import React, { useContext, useEffect, Dispatch } from "react";

import { DocumentList } from "./document-list";

import { DispatchContext, DocumentViewMode, ActionType, Action, Medium } from "../state";

export const DocumentsPage = (props: any) => {
    const dispatch: Dispatch<Action> = useContext(DispatchContext);

    useEffect(() => {
        if (props.match.params.id) {
            dispatch({ type: ActionType.ShowDocumentViewer, id: props.match.params.id })
        }
        else if (props.location.search) {
            const query = new URLSearchParams(props.location.search);

            const categoryName = query.get("category");
            if (categoryName) {
                dispatch({ type: ActionType.AddFilteredCategory, category: categoryName });
            }

            let mediumName = query.get("medium");
            if (mediumName) {
                mediumName = mediumName.toLowerCase();
                if (mediumName === Medium.DOCUMENT.toLowerCase() || mediumName === Medium.VIDEO.toLowerCase()) {
                    const medium = mediumName === Medium.VIDEO.toLowerCase() ? Medium.VIDEO : Medium.DOCUMENT;
                    dispatch({ type: ActionType.AddFilteredMedium, medium: medium });
                }
            }
        }
    }, []);

    return (
        <DocumentList view={ DocumentViewMode.Documents } />
    );
}
