import React, { useContext } from "react";

import { AppStateContext, AppState, SiteContent, SiteContentContext, Document, FileType } from "./state";

import { RequestAccess } from "./request-access";
import { build } from "./elementBuilder";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileVideo } from '@fortawesome/free-regular-svg-icons'

export const HomePage = (props: any) => {
    const appState: AppState = useContext(AppStateContext);
    const content: SiteContent = useContext(SiteContentContext);

    const homeElement: JSX.Element = build(content.homeBody);
    const latestVideosElement: JSX.Element = appState.documents.some(d => d.file.type === FileType.VIDEO)
        ? <div className="latest-videos">
            <h1>Latest Videos</h1>
            <ul>
                {appState
                    .documents
                    .filter((document: Document) => document.file.type === FileType.VIDEO)
                    .sort((a, b) => a.date < b.date ? 1 : -1)
                    .slice(0, 3)
                    .map((document: Document) => <li key={document.id} className="document">
                        <h2><a href={ `/documents/${document.id}?medium=video` }><FontAwesomeIcon icon={faFileVideo} className="icon" /> {document.title} (VIDEO)</a></h2>
                        <div className="details">
                            <time dateTime={document.date.toLocaleDateString("en-US")}>{document.date.toLocaleDateString("en-US", { year: 'numeric', month: 'long', day: 'numeric' })}</time>
                            <span><span>Category:</span> {document.categories.join(", ")}</span>
                            {document.tags.length > 0 && <span><span>Tags:</span> {document.tags.join(", ")}</span>}
                        </div>
                    </li>)
                }
            </ul>
            <h3><a href="/documents?medium=video">View All Videos</a></h3>
        </div>
        : <></>;

    return (
        <section id="home-page">
            {!appState.hasAccess && <>
                <RequestAccess />
                <div style={{
                    flex: "1 0 100%",
                    margin: "2rem 0 0",
                    backgroundColor: "transparent",
                    border: "0 none",
                    maxWidth: "100%"
                }}>
                    <div className="home-content">{homeElement}</div>
                </div>
            </>}
            {appState.hasAccess &&
                <>
                    <div className="home-content">{homeElement}</div>
                    {latestVideosElement}
                </>}
        </section>
    );
}