import React, { useReducer, useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import { RoutedApp } from "./routed-app";

import { DocumentViewer } from "./documents/document-viewer";
import { SplashScreen } from "./splash-screen";

import { appReducer, initialAppState, AppStateContext, DispatchContext, DocumentViewerContext, ActionType, SiteContentContext, emptySiteContent } from "./state";
import { build } from "./elementBuilder";
import useFetch, { FetchResponse } from "./useFetch";

export const App = () => {
    // State
    const [appState, dispatch] = useReducer(appReducer, initialAppState);
    const { documentViewer } = appState;

    const [showContent, setShowContent] = useState(false); // This keeps content from flashing

    // Authentication
    const { user, isLoading, isAuthenticated } = useAuth0();

    // Content
    const [content, setContent] = useState(emptySiteContent);

    // API
    const appDataApi = useFetch("/api/app");
    const siteContentAuthenticatedApi = useFetch("/api/content/authenticated");
    const siteContentUnAuthenticatedApi = useFetch("/api/content");
    const checkApi = useFetch("/api/registration/check");

    // useFetch does not have dispatch context here and will not dispatch loading actions
    const getAppData = () => {
        dispatch({ type: ActionType.StartLoading });

        appDataApi
            .get()
            .then((response: FetchResponse) => {
                if (response.success) {
                    const result = response.result;
                    dispatch({
                        type: ActionType.AppReceived,
                        documents: result.documents,
                        categories: result.categories,
                        tags: result.tags,
                        bookmarkIds: result.bookmarkIds,
                        recentIds: result.recentIds
                    });

                    dispatch({ type: ActionType.EndLoading });

                    // This handles loading site content for logged in users with site access
                    getSiteContent();
                }
            });
    }

    const getSiteContent = () => {
        dispatch({ type: ActionType.StartLoading });

        const siteContentApi = isAuthenticated ? siteContentAuthenticatedApi : siteContentUnAuthenticatedApi;

        siteContentApi
            .get()
            .then((response: FetchResponse) => {
                if (response.success) {
                    setContent(response.result);
                    setShowContent(true);
                    dispatch({ type: ActionType.EndLoading });
                }
            });
    };

    // Effects
    useEffect(() => {
        if (user && user.email) {
            const userEmailAddress = user.email;
            dispatch({ type: ActionType.StartLoading });

            checkApi
                .post(userEmailAddress)
                .then((response: FetchResponse) => {
                    if (response.success) {
                        if (response.result.userStatus === "Active") {
                            dispatch({ type: ActionType.GrantAccess });
                            dispatch({ type: ActionType.EndLoading });
                            // Load app state
                            getAppData();
                        }
                        else {
                            dispatch({ type: ActionType.EndLoading });

                            // This handles loading site content for logged in users  withOUT site access
                            getSiteContent();
                        }
                    }
                });
        }
    }, [user]);

    useEffect(() => {
        if (isLoading) {
            dispatch({ type: ActionType.StartLoading });
        }
        else {
            dispatch({ type: ActionType.EndLoading });
            // This handles loading site content for logged out users
            if (!user) {
                getSiteContent();
            }
        }
    }, [isLoading]);

    // Render App
    const footerElement: JSX.Element = build(content.footerBody);

    return (
        <SplashScreen>
            <AppStateContext.Provider value={appState}>
                <DispatchContext.Provider value={dispatch}>
                    <SiteContentContext.Provider value={content}>
                        <Router>
                            <RoutedApp showContent={showContent} />
                            <footer>
                                {footerElement}
                            </footer>
                            <DocumentViewerContext.Provider value={documentViewer}>
                                <DocumentViewer />
                            </DocumentViewerContext.Provider>
                        </Router>
                    </ SiteContentContext.Provider>
                </DispatchContext.Provider>
            </AppStateContext.Provider>
        </SplashScreen>
    );
}