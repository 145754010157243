import React, { useContext } from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'

import { AppStateContext } from "./state";

export const LoadingIndicator = () => {
    const { loading } = useContext(AppStateContext);

    return (<>
        {loading &&
            <div className="loading-mask">
                <div className="loading">
                    <FontAwesomeIcon icon={faCircleNotch} spin />
                </div>
            </div>
        }
    </>);
}