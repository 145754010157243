import React, { useContext, Dispatch, useEffect, useState } from "react";

import { Modal } from "react-dialog-polyfill";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'

import { BookmarkControl } from "./bookmark-control";
import { DownloadControl } from "./download-control";

import { DispatchContext, ActionType, DocumentContext, FileType, DocumentViewerContext, Action } from "../state";
import useFetch, { FetchResponse } from "../useFetch";
import { useHistory } from "react-router";

export const DocumentViewer = (props: any) => {
    const dispatch: Dispatch<Action> = useContext(DispatchContext);
    const { document, show } = useContext(DocumentViewerContext);

    const [loading, setLoading] = useState(true);
    const [dataUri, setDataUri] = useState("about:blank");
    const [videoPlayerId, setVideoPlayerId] = useState("");

    const fileApi = useFetch("/api/file");
    const videoApi = useFetch("/api/file/video");

    const history = useHistory();

    const getDocument = () => {
        fileApi
            .get(document.id)
            .then((response: FetchResponse) => {
                if (response.success) {
                    setDataUri(`data:application/pdf;base64,${response.result.file}`);
                    setLoading(false);
                }
            });
    }

    const getVideo = () => {
        let videoPlayerIdentifier = `video-player-${document.id}`;
        setVideoPlayerId(videoPlayerIdentifier);
        videoApi
            .get(document.id)
            .then((response: FetchResponse) => {
                if (response.success) {
                    setLoading(false);

                    const container = window.document.querySelector(`#${videoPlayerIdentifier}`);



                    new VdoPlayer({
                        otp: response.result.otp,
                        playbackInfo: response.result.playbackInfo,
                        theme: "9ae8bbe8dd964ddc9bdb932cca1cb59a",
                        container: container
                    });
                }
            });
    }

    useEffect(() => {
        if (document.id) {
            if (document.file.type === FileType.PDF) {
                getDocument();
            }
            else if (document.file.type === FileType.VIDEO) {
                getVideo();
            }
            else {
                setLoading(false);
            }
        }
    }, [document])

    const closeModal = (): void => {
        dispatch({ type: ActionType.HideDocumentViewer });
    }

    const onClose = (): void => {
        const pathParts = history.location.pathname.split("/").filter(p => p);
        if (pathParts.length > 1) {
            history.push(`/${pathParts[0]}`)
        }
        setLoading(true);
    }

    const documentDisplay = (): JSX.Element => {
        switch (document.file.type) {
            case FileType.PDF:
                return (<iframe src={dataUri} title="Document"></iframe>);
            case FileType.VIDEO:
                return (<div id={videoPlayerId} className="video-container">&nbsp;</div>);
            case FileType.WORD:
            case FileType.EXCEL:
            case FileType.POWERPOINT:
            default:
                return (<div className="download-button"><DownloadControl></DownloadControl></div>);
        }
    }

    return (
        <DocumentContext.Provider value={document}>
            <Modal
                className="modal document-viewer"
                open={show}
                onClose={() => {onClose()}}
                onCancel={() => {closeModal()}}
            >
                <div id="modal-container">
                    <div className="close-viewer">
                        <button onClick={()=>closeModal()}>X</button>
                    </div>
                    { document &&
                        <article className="document">
                            <header>
                                <h1>{document.title}</h1>
                            </header>
                            <div className="details">
                                <time dateTime={document.date.toLocaleDateString("en-US")}>{document.date.toLocaleDateString("en-US", {year: 'numeric', month: 'long', day: 'numeric'})}</time>
                                <BookmarkControl />
                                <DownloadControl />
                                <span><span>Category:</span> {document.categories.join(",") }</span>
                                { document.tags.length > 0 && <span><span>Tags:</span> {document.tags.join(",")}</span> }
                        </div>
                        <div className="viewer">
                            {loading &&
                                <div style={{ width: "100%", marginTop: "2rem", opacity: ".3", fontSize: "3rem", textAlign: "center" }}>
                                    <FontAwesomeIcon icon={faCircleNotch} spin />
                                </div>}
                            {!loading && documentDisplay()}
                        </div>
                        </article>
                    }
                </div>
            </Modal>
        </DocumentContext.Provider>
    );
}