import React, { useContext, Dispatch } from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import { AppStateContext, DispatchContext, ActionType, Action, AppState } from "../state";

export const CategoryList = () => {
    const appState: AppState = useContext(AppStateContext);
    const dispatch: Dispatch<Action> = useContext(DispatchContext);

    const addCategory = (category: string): void => {
        dispatch({type: ActionType.AddFilteredCategory, category: category});
    }

    const removeCategory = (category: string): void => {
        dispatch({type: ActionType.RemoveFilteredCategory, category: category});
    }

    const clearCategories = (): void => {
        dispatch({type: ActionType.ClearFilteredCategories});
    }

    const selectedCategories: string[] = appState.filter.categories;
    const unselectedCategories: string[] = appState.categories.filter(category => selectedCategories.indexOf(category) === -1 );

    const selectedCategoryListItems: JSX.Element[] = selectedCategories
        .map(category =>
            <li key={category}>
                <FontAwesomeIcon icon={faTimes} className="icon link" onClick={() => removeCategory(category)} />{category}
            </li>
        );

    const unselectedCategoryListItems: JSX.Element[] = unselectedCategories
        .sort((a, b) => {
            const nameA = a.toLowerCase();
            const nameB = b.toLowerCase();
            return (nameA < nameB) ? -1 : (nameA > nameB) ? 1 : 0;
        })
        .map(category =>
            <li key={category} className="link" onClick={() => addCategory(category)}>
                {category}
            </li>
        );

    return (
        <section>
            <header>
                <h1>Categories</h1> {selectedCategories.length > 0 && <span className="link" onClick={() => clearCategories()}>Clear All</span>}
            </header>
            <div className="content">
                {selectedCategories.length > 0 &&
                    <ul className="selected">
                        {selectedCategoryListItems}
                    </ul>
                }

                <ul>
                    {unselectedCategoryListItems}
                </ul>
            </div>
        </section>
    );
}