import * as React from "react";
import * as ReactDOM from "react-dom";
import { Auth0Provider } from "@auth0/auth0-react";

import { App } from "./app";

fetch("/api/auth0")
    .then(async (response) => {
        if (response.ok) {
            const result = await response.json();
            ReactDOM.render(
                <Auth0Provider
                    domain={result.domain}
                    clientId={result.clientId}
                    authorizationParams={{
                        redirect_uri: window.location.origin,
                        audience: result.audience
                    }}>
                    <App />
                </Auth0Provider>,
                document.getElementById('app')
            );
        }
    });