import React, { useEffect, useState, useContext, Dispatch } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'

import { navigation, DispatchContext, NavigationItem, ActionType, Action, AppState, AppStateContext } from "./state";


export const SiteHeader = () => {
    const appState: AppState = useContext(AppStateContext);
    const dispatch: Dispatch<Action> = useContext(DispatchContext);
    const history = useHistory();
    const [show, setShow] = useState(false);
    const { logout } = useAuth0();
    const [mql, setMql] = useState(window.matchMedia('(max-width: 767px)'));

    useEffect(() => {
        window.addEventListener('resize', () => { setMql(window.matchMedia('(max-width: 767px)')) });
    }, []);

    // Helpers
    const navigateToRoute = (navItem: NavigationItem) => {
        setShow(false);
        dispatch({ type: ActionType.ClearAllFilters });
        history.push(navItem.path);
    }

    // Rendering
    const accountNav = navigation.filter(n => n.name === "account")[0];
    const accountNavItem = <a href={accountNav.path} className={history.location.pathname === accountNav.path ? "selected link" : "link"} onClick={(e) => { e.preventDefault(); navigateToRoute(accountNav); }} style={{ marginRight: "1rem", paddingRight: "1rem", borderRight: "1px solid #ccc" }}>My Account</a>;
    const logOutNavItem = <span className="link" onClick={() => logout({
        logoutParams: {
            returnTo: window.location.origin
        } })}>Log out</span>;

    const navigationListItems: JSX.Element[] = navigation
        .filter((navItem: NavigationItem) => navItem.path.indexOf(":") === -1)
        .map((navItem: NavigationItem, index: number) => {
            return (
                <li className={navItem.name} key={index}>
                    <a onClick={(e) => { e.preventDefault(); navigateToRoute(navItem); }}
                        className={history.location.pathname === navItem.path ? "selected link" : "link"}
                        href={navItem.path}>
                        {navItem.displayName}
                    </a>
                </li>
            );
        });

    const nav = !appState.hasAccess
                ? <></>
                : (<nav>
                        { mql.matches && <FontAwesomeIcon onClick={() => setShow(!show)} icon={faBars} className={show ? "icon show" : "icon"} />}
                        <ul className={show ? "show" : ""}>
                            {navigationListItems}
                            <li className="log-out">{logOutNavItem}</li>
                        </ul>
                    </nav>);

    const userInfo = !appState.hasAccess
        ? <></>
        : <>
            {accountNavItem}{logOutNavItem}
        </>

    return (
        <header id="site-header">
            <div id="logo">
                <img src="/theme/logo" alt="logo" />
            </div>
            <div id="login">
                {userInfo}
            </div>
            {nav}
        </header>);
}