import React, { useContext, Dispatch } from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import { AppStateContext, DispatchContext, ActionType, Action, AppState } from "../state";

export const TagList = () => {
    const appState: AppState = useContext(AppStateContext);
    const dispatch: Dispatch<Action> = useContext(DispatchContext);

    const addTag = (tag: string): void => {
        dispatch({type: ActionType.AddFilteredTag, tag: tag});
    }

    const removeTag = (tag: string): void => {
        dispatch({type: ActionType.RemoveFilteredTag, tag: tag});
    }

    const clearTags = (): void => {
        dispatch({type: ActionType.ClearFilteredTags});
    }

    const selectedTags: string[] = appState.filter.tags;
    const unselectedTags: string[] = appState.tags.filter(tag => selectedTags.indexOf(tag) === -1 );

    const selectedTagListItems: JSX.Element[] = selectedTags
        .map(tag =>
            <li key={tag}>
                <FontAwesomeIcon icon={faTimes} className="icon link" onClick={() => removeTag(tag)} />{tag}
            </li>
        );

    const unselectedTagListItems: JSX.Element[] = unselectedTags
        .sort((a, b) => {
            const nameA = a.toLowerCase();
            const nameB = b.toLowerCase();
            return (nameA < nameB) ? -1 : (nameA > nameB) ? 1 : 0;
        })
        .map(tag =>
            <li key={tag} className="link" onClick={() => addTag(tag)}>
                {tag}
            </li>
        );

    return (
        <section>
            <header>
                <h1>Tags</h1> {selectedTags.length > 0 && <span className="link" onClick={() => clearTags()}>Clear All</span>}
            </header>
            <div className="content">
                {selectedTags.length > 0 &&
                    <ul className="selected">
                        {selectedTagListItems}
                    </ul>
                }
                <ul>
                    {unselectedTagListItems}
                </ul>
            </div>
        </section>
    );
}