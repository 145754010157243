import * as React from "react";
import useFetch, { FetchResponse } from "./useFetch";
import { useEffect, useState, FormEvent } from "react";
import { useAuth0 } from "@auth0/auth0-react";

export const AccountPage = () => {
    // State
    const userApi = useFetch("/api/user");

    const passwordResetApi = useFetch("/api/user/email/password");

    const [account, setAccount] = useState({ firstName: "", lastName: "", organization: "" });
    const [passwordChangeProcessing, setPasswordChangeProcessing] = useState(false)
    const [passwordChangeRequested, setPasswordChangeRequested] = useState(false);
    const [loading, setLoading] = useState(true);

    // Authentication
    const { user } = useAuth0();

    // Effects
    useEffect(() => {
        if (user) {
            userApi
                .get()
                .then((response: FetchResponse) => {
                    if (response.success) {
                        setAccount(
                            {
                                firstName: response.result.firstName,
                                lastName: response.result.lastName,
                                organization: response.result.organization
                            }
                        );
                        setLoading(false);
                    }
                });
        }
    }, [])

    const handleSubmit = (event: FormEvent): void => {
        event.preventDefault();
        if (user) {
            userApi
                .put(user.email as string, account)
                .then((response: FetchResponse) => {
                    if (response.success) {
                    }
                });
        }
    }

    const sendPasswordResetEmail = (event: any): void => {
        event.preventDefault();
        setPasswordChangeProcessing(true);
        passwordResetApi
            .post({})
            .then((response: FetchResponse) => {
                if (response.success) {
                    setPasswordChangeRequested(true);
                    setPasswordChangeProcessing(false);
                }
                else {
                    setPasswordChangeRequested(false);
                    setPasswordChangeProcessing(false);
                }
            });
    }

    return (
        <section id="account-page">
            <form onSubmit={handleSubmit}>
                {!loading &&
                    <>
                        <div>
                            <div>
                                <label htmlFor="firstName">
                                    First Name
                                    {!account.firstName && <span style={{ color: "#f00", fontSize: "1rem" }}> Required</span>}
                                </label>
                                <input
                                    id="firstName"
                                    name="firstName"
                                    type="text"
                                    value={account.firstName}
                                    onChange={(e) => { setAccount({ ...account, firstName: e.target.value }) }}
                                    className={!account.firstName ? "warn" : ""}
                                />

                                <label htmlFor="lastName">
                                    Last Name
                                    {!account.lastName && <span style={{ color: "#f00", fontSize: "1rem" }}> Required</span>}
                                </label>
                                <input
                                    id="lastName"
                                    name="lastName"
                                    type="text"
                                    value={account.lastName}
                                    onChange={(e) => { setAccount({ ...account, lastName: e.target.value }) }}
                                    className={!account.lastName ? "warn" : ""}
                                />

                                <label htmlFor="organization">
                                    Organization
                                    {!account.organization && <span style={{ color: "#f00", fontSize: "1rem" }}> Required</span>}
                                </label>
                                <input
                                    id="organization"
                                    name="organization"
                                    type="text"
                                    value={account.organization}
                                    onChange={(e) => { setAccount({ ...account, organization: e.target.value }) }}
                                    className={!account.organization ? "warn" : ""}
                                />

                                <input type="button" value="Change Password" onClick={(e) => { sendPasswordResetEmail(e) }} disabled={passwordChangeProcessing || passwordChangeRequested} style={{ display: "inline", fontSize: "0.8rem" }} />
                                <span style={{ display: "inline", fontSize: "0.8rem" }}> {passwordChangeRequested && <>  &nbsp; Please check your email for instructions on changing your password. If you don't see it, please check your spam folder.</>}</span>
                            </div>
                        </div>
                        <input type="submit" value="Save Changes" disabled={!account.firstName || !account.lastName || !account.organization} />
                    </>
                }
            </form>
        </section>
    );
}