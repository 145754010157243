import React, { useEffect } from "react";

import { Switch, Route, useHistory, useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import { HomePage } from "./home-page";
import { DocumentsPage } from "./documents/documents-page";
import { BookmarksPage } from "./documents/bookmarks-page";
import { RecentsPage } from "./documents/recents-page";
import { AccountPage } from "./account-page";
//import { HelpPage } from "./help-page";
import { EmailVerified } from "./email-verified-page";
import { PasswordReset } from "./password-reset-page";
import { BlankPage } from "./blank-page";
import { SiteHeader } from "./site-header";

import { LoadingIndicator } from "./loading-indicator";

import { navigation } from "./state";

export const RoutedApp = (props: { showContent: boolean }) => {
    const { user } = useAuth0();
    const history = useHistory();
    const location = useLocation();

    const nonProtectedRoutes: { name: string; path: string}[] = [
        {
            name: "verified",
            path: "/verified"
        },
        {
            name: "reset",
            path: "/reset"
        },
    ];

    useEffect(() => {
        const onNonProtectedRoute = nonProtectedRoutes.findIndex(r => r.path === location.pathname) >= 0;

        if (!user && !onNonProtectedRoute) {
            history.push("/");
        }
    }, [user]);

    const getPath = (name: string): string => {
        return navigation.filter(n => n.name === name)[0].path;
    }

    const getNonProtectedPath = (name: string): string => {
        return nonProtectedRoutes.filter(n => n.name === name)[0].path;
    }

    return (
        <>
            <SiteHeader />
            <main>
                <LoadingIndicator />
                {props.showContent &&
                    <Switch>
                        <Route exact path={getPath("home")} component={HomePage} />
                        <Route exact path={getPath("document")} component={DocumentsPage} />
                        <Route exact path={getPath("documents")} component={DocumentsPage} />
                        <Route exact path={getPath("bookmarks")} component={BookmarksPage} />
                        <Route exact path={getPath("recent")} component={RecentsPage} />
                        <Route exact path={getPath("account")} component={AccountPage} />
                        {/*<Route exact path={getPath("help")} component={HelpPage} />*/}
                        <Route exact path={getNonProtectedPath("verified")} component={EmailVerified} />
                        <Route exact path={getNonProtectedPath("reset")} component={PasswordReset} />
                        <Route component={() => <BlankPage />} />
                    </Switch>
                }
            </main>
        </>
    );
}
