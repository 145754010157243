import React from "react";

import { useAuth0 } from "@auth0/auth0-react";
import { LoadingIndicator } from "./loading-indicator";

export const SplashScreen = (props: any) => {
    const { isLoading } = useAuth0();
    const body = isLoading ? <LoadingIndicator /> : props.children
    return (<>
        { body }
    </>);
}